<template>
  <div>
    <Molded>
      <Badge :title="'Nº ' + companyId" type="primary" tamanho="small" />
      <div class="titulo">
        {{ company.personResponse.companyName }} -
        {{ company.personResponse.tradeName }}
      </div>
    </Molded>
    <br />
    <b-tabs>
      <b-tab title="Empresa">
        <Company :companyResponse="company" v-model="company" />
      </b-tab>
      <b-tab title="Módulos">
        <RuleCompany />
      </b-tab>
      <b-tab title="Usuários">
        <UserList />
      </b-tab>
      <b-tab title="Plano">
        <Plan :planResponse="company.planResponse" v-model="plan" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import UserList from "./user/UserList.vue";
import Badge from "@nixweb/nixloc-ui/src/component/layout/Badge";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import RuleCompany from "./RuleCompany.vue";

import Plan from "./company/Plan.vue";
import Company from "./company/Company.vue";

import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "CompanyUpdate",
  components: {
    Badge,
    Molded,
    RuleCompany,
    Plan,
    Company,
    UserList,
  },
  data() {
    return {
      companyId: this.$route.params.id,
      formNome: "empresaEditar",
      company: {},
      plan: {},
      urlGetById: "/api/v1/support/company/get-by-id",
      urlUpdate: "/api/v1/support/company/update",
      urlGetCodeLoginTemp: "/api/v1/support/auth/company/get-code-login-temp/",
    };
  },
  created() {
    this.getById();
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
    ...mapState("user", ["userLogged"]),
  },
  methods: {
    ...mapActions("generic", ["getApi", "postApi", "putApi"]),
    ...mapMutations("generic", ["addLoading", "removeLoading"]),
    getById() {
      let params = { url: this.urlGetById, obj: { id: this.companyId } };
      this.getApi(params).then((response) => {
        this.company = response.content;
        this.removeLoading(["panel"]);
      });
    },
    update() {
      let companyRequest = {
        id: this.companyId,
        personRequest: this.company.personResponse,
        contactRequest: this.company.contactResponse,
        addressRequest: {
          zipCode: this.company.addressResponse.zipCode,
          street: this.company.addressResponse.street,
          number: this.company.addressResponse.number,
          complement: this.company.addressResponse.complement,
          province: this.company.addressResponse.province,
          city: this.company.addressResponse.city,
          state: this.company.addressResponse.state.content,
        },
        planRequest: {
          numberOfUsers: this.plan.numberOfUsers,
          numberOfDocuments: this.plan.numberOfDocuments,
          numberOfOpenFinance: this.plan.numberOfOpenFinance,
        },
      };
      let params = { url: this.urlUpdate, obj: companyRequest };
      this.putApi(params).then((response) => {
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    loginTemp() {
      let loginTemp = {
        companyId: this.companyId,
      };
      let params = {
        url: this.urlGetCodeLoginTemp,
        obj: loginTemp,
        naoNotificarToast: true,
      };
      this.postApi(params).then((response) => {
        if (response.success) {
          let ret = response.content;
          let env = process.env.VUE_APP_API_URL_NIXLOC;
          // let env = "http://localhost:8080/login-temp/";
          let url = `${env}${ret.codeLoginTemp}/${this.companyId}`;
          window.open(url, "_blank");
        }
        this.removeLoading(["accessCompanyAccount"]);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name === "accessCompanyAccount") this.loginTemp();
        if (event.name == "saveSaveCancel") this.update();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.titulo {
  margin-top: 10px;
  font-size: 16px;
}
</style>
